import axios from '@/axios.js'

export default {
  fetchReservationStatuses ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('reservation-status')
        .then(({data: response}) => {
          commit('SET_RESERVATION_STATUSES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchReservationStatus (context, reservationStatusID) {
    return new Promise((resolve, reject) => {
      axios.get(`reservation-status/${reservationStatusID}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storeReservationStatus (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('reservation-status', payload)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updateReservationStatus (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`reservation-status/${payload.id}`, payload.body)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroyReservationStatus ({ commit }, reservationStatusID) {
    return new Promise((resolve, reject) => {
      axios.delete(`reservation-status/${reservationStatusID}`)
        .then((response) => {
          commit('REMOVE_RECORD', reservationStatusID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
