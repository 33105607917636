import state from './moduleReservationStatusManagementState.js'
import mutations from './moduleReservationStatusManagementMutations.js'
import actions from './moduleReservationStatusManagementActions.js'
import getters from './moduleReservationStatusManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

